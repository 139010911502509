import React from 'react'

const Loading = () => {
  return (
    <>
    <div className='loading-container'>
      <div className="loader"></div>
    </div>
    </>
  )
}

export default Loading
